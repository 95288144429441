<template>
  <div class="signin">
    <v-container class="text-center">
      <v-row>
        <v-col class="subtitle" style="margin-right: 30px; margin-left: 30px">
          <h2>ログイン</h2>
          <v-form>
            <v-text-field
              label="メールアドレス"
              placeholder=""
              v-model="username"
            ></v-text-field>
            <v-text-field
              label="パスワード"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              v-model="password"
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-btn
              class="ma-3"
              depressed
              outlined
              color=""
              large
              @click="signIn"
              >ログイン</v-btn
            >
          </v-form>
          <router-link to="/passwordreset">
            <div class="my-4 subtitle-1">
              パスワードがわからない方
            </div></router-link
          >
          <router-link to="/signup">
            <div class="my-4 subtitle-1">会員登録はこちら</div></router-link
          >
          <router-link :to="{ name: 'about' }">
            <div class="my-4 subtitle-1">俳句ノートとは？</div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <!-- <footerHaikulife></footerHaikulife> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
// import footerHaikulife from "./Footer";

export default {
  name: "Signin",
  //  components: {
  //   footerHaikulife,
  // },
  data() {
    return {
      username: "",
      password: "",
      show1: false,
    };
  },
  methods: {
    signIn: function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.username, this.password)
        .then(() => {
          // alert("Success!");
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.code == "auth/invalid-email") {
            alert("メールの形式が正しくありません。");
          } else if (error.code == "auth/wrong-password") {
            alert("パスワードが違います。");
          } else if (error.code == "auth/user-not-found") {
            alert("メールアドレスが存在しません。");
          } else {
            alert(error.message);
          }
        });
    },
  },
};
</script>
<style scoped>
.signin {
  margin-top: 20px;
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center; */
}
input {
  margin: 10px 0;
  padding: 10px;
}
</style>

