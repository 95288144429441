<template>
  <div class="setting">
    <v-container class="text-center">
      <v-container v-if="!isHaigouEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <div v-if="userdetail">俳号：{{ userdetail.haigou }}</div>
          <v-spacer></v-spacer>
          <button @click="haigouEdit">編集</button>
        </v-row>
      </v-container>

      <v-container v-if="isHaigouEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <v-text-field v-model="haigou"></v-text-field>
          <v-spacer></v-spacer>
          <button @click="cancelHaigouEditMode">キャンセル</button>
          <v-spacer></v-spacer>
          <button @click="writeHaigouData">保存</button>
        </v-row>
      </v-container>

      <v-container v-if="!isEmailEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <div v-if="userdetail">email：{{ userdetail.email }}</div>
          <v-spacer></v-spacer>
          <button @click="emailEdit">編集</button>
        </v-row>
      </v-container>

      <v-container v-if="isEmailEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <v-text-field v-model="email"></v-text-field>
          <v-spacer></v-spacer>
          <button @click="cancelEmailEditMode">キャンセル</button>
          <v-spacer></v-spacer>
          <button @click="writeEmailData">保存</button>
        </v-row>
      </v-container>

      <v-container v-if="!isPasswordEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <div v-if="userdetail">パスワード：**********************</div>
          <v-spacer></v-spacer>
          <button @click="passwordEdit">編集</button>
        </v-row>
      </v-container>
      <v-container v-if="isPasswordEditMode">
        <v-row no-gutters style="padding-top: 25px">
          <v-text-field v-model="password"></v-text-field>
          <v-spacer></v-spacer>
          <button @click="cancelPasswordEditMode">キャンセル</button>
          <v-spacer></v-spacer>
          <button @click="writePasswordData">保存</button>
        </v-row>
      </v-container>
      <div class="py-3"></div>
      <v-container>
        <v-row no-gutters style="padding-top: 25px">
          <v-spacer></v-spacer>
          <router-link to="/explanation"> 俳句ノートについて </router-link>
          <v-spacer></v-spacer>
        </v-row>
        <div class="py-3"></div>
        <v-row no-gutters style="padding-top: 25px">
          <v-spacer></v-spacer>
          <button @click="deleteAccount_kakunin">アカウントを削除する</button>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-container>

    <v-dialog v-model="dialog_delete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> お手数おかけします。 </v-card-title>
        <v-card-text>
          お手数おかけし、申し訳ありません。support@haikunote.jpまで「アカウント削除」と記載してメールをお送り下さい。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialg">
            キャンセル
          </v-btn>
          <!-- <v-btn color="green darken-1" text @click="deleteAccount">
            削除する
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import mixin from "../plugins/mixin.js";
import firebase from "firebase/app";

export default {
  name: "Setting",
  mixins: [mixin],
  data() {
    return {
      haigou: "",
      email: "",
      password: "",
      isEmailEditMode: false,
      isHaigouEditMode: false,
      isPasswordEditMode: false,
      dialog_delete: false,
    };
  },
  created: function () {},
  methods: {
    writeHaigouData: function () {
      let self = this;
      if (this.haigou === "") {
        alert("俳号は必須入力です");
        return;
      }
      this.isHaigouOK(this.haigou, self).then(onHaigouOK, onRejectedHaigouOK);
      //俳号チェックがOKだったときの処理
      function onHaigouOK() {
        var user = firebase.auth().currentUser;
        db.ref("users/" + self.user.uid).set({
          haigou: self.haigou,
          email: self.userdetail.email,
          userId: self.userdetail.userId,
          photoUrl: "this.user.photoURL",
        });
        self.isHaigouEditMode = false;
        self.setUserDetail(user);
      }
      //俳号チェックがNGだったときの処理
      function onRejectedHaigouOK(error) {
        alert(error);
      }
    },

    writeEmailData: function () {
      if (this.email === "") {
        alert("メールアドレスは必須入力です");
        return;
      }

      var user = firebase.auth().currentUser;
      let self = this;
      user
        .updateEmail(self.email)
        .then(function () {
          db.ref("users/" + user.uid).set({
            photoUrl: user.photoURL,
            haigou: self.userdetail.haigou,
            userId: self.userdetail.userId,
            email: self.email,
          });
          self.isEmailEditMode = false;
          self.setUserDetail(user);
          alert("メールアドレスを変更しました。");
        })
        .catch(function () {
          alert(
            "メールアドレスの変更に失敗しました。お手数ですが、ログインし直してから再度お試しください。"
          );
        });
    },
    writePasswordData: function () {
      var user = firebase.auth().currentUser;
      let self = this;
      user
        .updatePassword(self.password)
        .then(function () {
          console.log("success!");
        })
        .catch(function () {
          alert(
            "パスワードの変更に失敗しました。お手数ですが、再度ログインし直してからお試し下さい。"
          );
        });
      self.isPasswordEditMode = false;
    },

    haigouEdit: function () {
      this.isHaigouEditMode = true;
      if (this.userdetail) {
        //現在の俳号をテキストボックスに初期値として設定
        this.haigou = this.userdetail.haigou;
      }
    },
    emailEdit: function () {
      this.isEmailEditMode = true;
      if (this.userdetail) {
        //現在のemailをテキストボックスに初期値として設定
        this.email = this.userdetail.email;
      }
    },
    passwordEdit: function () {
      this.isPasswordEditMode = true;
    },
    cancelHaigouEditMode: function () {
      this.isHaigouEditMode = false;
    },
    cancelEmailEditMode: function () {
      this.isEmailEditMode = false;
    },
    cancelPasswordEditMode: function () {
      this.isPasswordEditMode = false;
    },

    isHaigouOK: function (haigou, self) {
      return new Promise(function (resolve, reject) {
        db.ref("users").once("value", (users) => {
          users.forEach((element) => {
            if (element.val().haigou === haigou) {
              //自分の元の俳号と同じでなければ重複エラー
              if (element.key !== self.user.uid) {
                return reject("すでに利用されている俳号です。");
              }
            }
          });
          return resolve();
        });
      });
    },
    deleteAccount_kakunin: function () {
      this.dialog_delete = true;
    },
    closeDialg: function () {
      this.dialog_delete = false;
    },
    deleteAccount: function () {
      var user = firebase.auth().currentUser;
      user
        .delete()
        .then(function () {
          alert("アカウント削除しました。またのご利用お待ちしております。");
        })
        .catch(function () {
          alert(
            "アカウント削除に失敗しました。お手数ですが再度ログインしてお試し下さい。"
          );
        });
    },
  },
};
</script>