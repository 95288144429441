import { db } from "./firebase";
import firebase from 'firebase/app';
export default {
    data() {
        return {
            user: {},
            userdetail: [],
        };
    },
    created: function () {
        firebase.auth().onAuthStateChanged((user_auth) => {

            if (user_auth) {
                if (user_auth.isAnonymous) {
                    return;
                }
            }
            
            this.user = user_auth ? user_auth : {}
            this.setUserDetail(user_auth);
        });
        this.setUserDetail(firebase.auth().currentUser);
    },
    methods: {
        setUserDetail: function (user) {
            if (user) {
                this.user = user;
                db.ref("users/" + this.user.uid).once("value", (snapshot) => {
                    this.userdetail = snapshot.val();
                });
            }
        }
    }
};
