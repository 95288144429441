<template>
  <div>
    <v-container>
      <!-- <banner></banner> -->
      <v-row dense>
        <v-col cols="12">
          <div v-if="tags">
            <v-sheet elevation="0" color="#f7f9f9">
              <v-chip-group mandatory active-class="primary--text">
                <v-chip class="tagChips" outlined @click="gotoHome">
                  ホーム
                </v-chip>
                <v-chip
                  class="tagChips"
                  v-for="tag in tags"
                  :key="tag.index"
                  outlined
                  v-bind:class="{ active: tag.selected }"
                  @click="selectTag(tag.key)"
                >
                  {{ tag.text }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h3>{{ selectionTagText }}</h3>
        </v-col>
      </v-row>
      <div v-for="item in sortedItem" v-bind:key="item.haikuid">
      <!-- <div v-for="item in items" v-bind:key="item.id"> -->
        <haikucard :haiku="item"></haikucard>
        <!-- <haikucardPicture></haikucardPicture> -->
      </div>
    </v-container>
  </div>
</template>


<script>
import { db } from "../plugins/firebase";
import haikucard from "./Haikucard";
// import banner from "./Banner";
// import haikucardPicture from "./HaikucardPicture";

export default {
  name: "Category",
  components: {
    haikucard,
    // haikucardPicture,
    // banner,
  },
  data() {
    return {
      items: [],
      tags: [],
      selectionTagText: "",
    };
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let self = this;
      var filterdHaikus = [];

      db.ref("haikus")
        .orderByChild("publish")
        .equalTo(true)
        .once("value", (haikus) => {
          //タグでフィルタ
          haikus.forEach((element) => {
            if (element.val().tags) {
              element.val().tags.forEach((element_work) => {
                if (element_work.tagId === this.$route.params.tagId) {
                  filterdHaikus.push(element);
                }
              });
            }
          });

          //俳句に紐づくユーザー情報を取得
          let data = [];
          filterdHaikus.forEach(function (haiku) {
            db.ref("users/" + haiku.val().user).once("value", (snapshot) => {
              var haigou = "名無し";
              var userid = "";
              if (snapshot.val()) {
                haigou = snapshot.val().haigou;
                userid = snapshot.val().userId;
              }
              data.push({
                haikuid: haiku.key,
                haiku: haiku.val().haiku,
                comment: haiku.val().comment,
                timestamp: haiku.val().createdAt,
                haigou: haigou,
                userId: userid,
                tags: haiku.val().tags,
                heartCount: haiku.val().heartCount,
              });
            });
          });
          self.items = data;
        });

      db.ref("tags/")
        .orderByChild("order")
        .once("value", (snapshot) => {
          self.tags = [];
          snapshot.forEach(function (tag) {
            if (tag.key === self.$route.params.tagId) {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: true,
              });
              self.selectionTagText = tag.val().text;
            } else {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: false,
              });
            }
          });
        });
    },
    gotoHome: function () {
      this.$router.push("/");
    },
    selectTag: function (selectTagIndex) {
      this.$router
        .push({
          name: "category",
          params: { tagId: selectTagIndex },
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    sortedItem() {
      return this.items.slice().sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData();
    next();
  },
};
</script>
<style>
.v-application a {
  color: black !important;
}
</style>

