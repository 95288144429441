import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import About from '@/components/About'
import Signup from '@/components/Signup'
import Signin from '@/components/Signin'
import New from '@/components/New'
import Haiku from '@/components/Haiku'
import Mypage from '@/components/Mypage'
import User from '@/components/User'
import Setting from '@/components/Setting'
import firebase from 'firebase/app'
import Edit from '@/components/Edit'
import PasswordReset from '@/components/PasswordReset'
import Category from '@/components/Category'
import Terms from '@/components/Terms'
import Privacy from '@/components/Privacy'
import Explanation from '@/components/Explanation'
import "firebase/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },
  {
    path: '/new',
    name: 'new',
    component: New,
    // meta: { requiresAuth: true }
  },
  {
    path: '/haikus/:id',
    name: 'haiku',
    component: Haiku
  },
  {
    path: '/mypage/',
    name: 'mypage',
    component: Mypage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'setting',
    component: Setting,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit/:haikuId',
    name: 'edit',
    component: Edit,
    meta: { requiresAuth: true }
  },
  {
    path: '/tags/:tagId',
    name: 'category',
    component: Category
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/passwordreset',
    name: 'passwordreset',
    component: PasswordReset,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
  {
    path: '/explanation',
    name: 'explanation',
    component: Explanation,
  },
  //:userIdページの設定は一番最後尾に書いておく必要がある
  {
    path: '/:userId',
    name: 'user',
    component: User
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let currentUser = firebase.auth().currentUser
  if (requiresAuth) {
    if (!currentUser) {
      next({
        path: '/signin',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() 
  }
})


export default router
