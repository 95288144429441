<template>
  <div class="mypage">
    <v-container>
      あなたが作成した俳句のリスト
      <v-row v-if="haikus.length == 0">
        <v-col>
          <div class="grey--text">まだ俳句を作成していません。<br /></div>
        </v-col>
      </v-row>
      <!-- <div v-for="(haiku, index) in haikus" :key="index"> -->
      <div v-for="haiku in sortedItem" :key="haiku.haikuId">
        <haikucard :haiku="haiku"></haikucard>
      </div>
    </v-container>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import firebase from "firebase/app";
import mixin from "../plugins/mixin.js";
import haikucard from "./HaikucardMypage";

export default {
  name: "Mypage",
  mixins: [mixin],
  components: {
    haikucard,
  },
  data() {
    return {
      haigou: "",
      comment: "",
      haikus: [],
    };
  },
  created: function () {
    firebase.auth().onAuthStateChanged((user_auth) => {
      if (user_auth) {
        this.getHaikusu();
      }
    });
    if (this.user) {
      this.getHaikusu();
    }
  },
  methods: {
    getHaikusu: function () {
      let self = this;
      try {
        db.ref("haikus")
          .orderByChild("user")
          .equalTo(this.user.uid)
          .once("value", (snapshot) => {
            let data = [];
            snapshot.forEach(function (item) {
              data.push({
                haikuid: item.key,
                haiku: item.val().haiku,
                comment: item.val().comment,
                timestamp: item.val().createdAt,
                haigou: "",
                userId: item.val().user,
                publish: item.val().publish,
                heartCount: item.val().heartCount,
              });
            });
            self.haikus = data;
          });
      } catch {
        console.log();
      }
    },
  },
  computed: {
    sortedItem() {
      return this.haikus.slice().sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });
    },
  },
};
</script>