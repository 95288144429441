<template>
  <div class="about">
    <v-row
      class="white lighten-4"
      style="height: 300px;margin:0 0 0 0"
      justify="center"
      align-content="center"
    >
      <v-col class="text-center">
        <div class="subtitle" style="margin-bottom: 10px">
          俳句をもっと楽しく!
        </div>
        <v-layout justify-center>
          <v-img
            :src="require('@/assets/logo.svg')"
            alt="logo"
            max-width="200"
          />
        </v-layout>
      </v-col>
    </v-row>
    <v-container class="text-center">
      <v-row justify="center" align-content="center">
        <v-col cols="11" class="title">
          俳句ノートは、俳句を投稿できるサイトです。 自分の俳句集を作れます。
        </v-col>
        <div class="py-12"></div>
        <v-col cols="11" class="subtitle-1">
          <router-link to="/explanation">もっと詳しく→</router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="text-center">
      <div class="py-3"></div>
      <v-btn depressed outlined color="#FAFAFA">
        <v-img
          :src="require('@/assets/Twitter_Social_Icon_Circle_Color.svg')"
          height="25"
          max-width="25"
          style="margin-right: 5px"
        />
        <a
          href="http://twitter.com/share?url=https://haikunote.jp/&text=俳句ノート&hashtags=俳句"
          target="_blank"
          >ツイートする</a
        >
      </v-btn>
    </v-container>
    <v-container class="text-center">
      <div class="py-3"></div>
      <v-btn class="ma-3" depressed outlined color="" large @click="signup"
        >会員登録（無料）</v-btn
      >
    </v-container>
    <div class="py-12"></div>
    <footerHaikulife></footerHaikulife>
  </div>
</template>
 <script>
import footerHaikulife from "./Footer";

export default {
  components: {
    footerHaikulife,
  },
  methods: {
    signup: function () {
      this.$router.push("/signup");
    },
  },
};
</script>