import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase/app';
import "firebase/auth"
import 'firebase/database';
import "firebase/analytics"

Vue.use(firestorePlugin)

const firebaseApp = firebase.initializeApp({

    //本番環境
    apiKey: "AIzaSyBqpXf-d6sWPLud388kPTpd46riWFGU9ao",
    authDomain: "mutsu-64206.firebaseapp.com",
    databaseURL: "https://mutsu-64206.firebaseio.com",
    projectId: "mutsu-64206",
    storageBucket: "mutsu-64206.appspot.com",
    messagingSenderId: "1053471313328",
    appId: "1:1053471313328:web:ad03dad376d440b7f81a75",
    measurementId: "G-37F63BTSMK"

    //開発環境
    // apiKey: "AIzaSyDjTpsTEBp9-Ls51_nbGbitjXmxIE6fKkw",
    // authDomain: "mutsu-dev.firebaseapp.com",
    // databaseURL: "https://mutsu-dev.firebaseio.com",
    // projectId: "mutsu-dev",
    // storageBucket: "mutsu-dev.appspot.com",
    // messagingSenderId: "760167971498",
    // appId: "1:760s167971498:web:33390dc1cf1651d5a1eed3",
    
});

firebase.analytics();
firebase.analytics().logEvent('notification_received');


export const db = firebaseApp.database();
