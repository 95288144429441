<template>
  <div class="new">
    <v-container>
      <h2 v-if="user.uid === void 0">俳句を詠む（匿名）</h2>
      <v-form ref="haiku_form">
        <h3>俳句</h3>
        <v-textarea
          class="textarea-haiku"
          outlined
          rows="4"
          background-color="white"
          placeholder="柿くへば 鐘が鳴るなり 法隆寺"
          :rules="[required]"
          v-model="haiku"
        ></v-textarea>
        <h3>コメント</h3>
        <v-textarea
          class="textarea-comment"
          background-color="white"
          rows="3"
          outlined
          placeholder=""
          v-model="comment"
        ></v-textarea>
        <haikuimage @my-click="reflectValue"></haikuimage>
        <div class="py-4"></div>
        <h3>タグを選んで下さい</h3>
        <!-- <v-card outlined elevation="0"> -->
        <!-- <v-container class="py-0"> -->
        <v-row align="center" justify="start">
          <div v-if="selected" class="ma-3">選択中</div>
          <div
            v-for="(selection, i) in selections"
            :key="selection.text"
            class="ma-1"
          >
            <v-chip
              :disabled="loading"
              close
              @click:close="selectedTag.splice(i, 1)"
              v-model="tag"
            >
              {{ selection.text }}
            </v-chip>
          </div>
        </v-row>
        <!-- </v-container> -->

        <!-- <v-divider v-if="!allSelected"></v-divider> -->
        <!-- <v-list> -->
        <v-container class="py-0">
          <v-row>
            <template v-for="item in categories">
              <div
                v-if="!selectedTag.includes(item)"
                :key="item.key"
                :disabled="loading"
                @click="selectedTag.push(item)"
                class="ma-1"
              >
                <v-chip
                  :disabled="loading"
                  close
                  outlined
                  @click:close="selectedTag.splice(i, 1)"
                  v-model="tag" 
                >
                {{item.text}}
                </v-chip>
              </div>
            </template>
          </v-row>
        </v-container>
        <!-- </v-list> -->
        <!-- </v-card> -->
        <v-row align="center">
          <v-container>
            <v-btn
              class="ma-3"
              depressed
              outlined
              color="primary"
              large
              @click="cancel"
              >キャンセル</v-btn
            >
            <v-btn
              class="ma-3"
              depressed
              color="primary"
              large
              @click="createPost"
              >保存</v-btn
            >
          </v-container>
        </v-row>
      </v-form>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="dialogAnnon" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            匿名で俳句を投稿しました。
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialgAnnon">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"> 保存しました。 </v-card-title>
          <v-card-text>公開する場合、公開設定を変更してください。</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_error" max-width="290">
        <v-card>
          <v-card-title class="headline"> 俳句は必須入力です。 </v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg_error">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogNotSIgninAlert" max-width="290">
        <v-card>
          <v-card-title class="headline"> ログインしていません</v-card-title>
          <v-card-text class="title"
            >匿名で俳句を投稿します。
            <div class="subtitle-2">※投稿後の編集はできません。</div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="goToSignInPage">
              ログイン
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="closeDialogNotSIgninAlert"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { db } from "../plugins/firebase.js";
import mixin from "../plugins/mixin.js";
import firebase from "firebase/app";
import haikuimage from "./HaikuImage";
import "firebase/storage";

export default {
  name: "New",
  mixins: [mixin],
  components: {
    haikuimage,
  },
  data() {
    return {
      haiku: "",
      comment: "",
      required: (value) => !!value || "",
      tag: "",
      dialog: false,
      tags: [],
      search: "",
      selectedTag: [],
      loading: false,
      dialog_error: false,
      dialogAnnon: false,
      dialogNotSIgninAlert: false,
      imageCropped: "",
      imageFile: [],
    };
  },
  created: function () {
    if (this.user.uid === void 0) {
      this.dialogNotSIgninAlert = true;
    }
    db.ref("tags")
      .orderByChild("order")
      .once("value", (snapShot) => {
        let data = [];
        snapShot.forEach(function (tag) {
          if (tag.val().publish) {
            data.push({
              text: tag.val().text,
              tagId: tag.key,
            });
          }
        });
        this.tags = data;
      });
  },
  methods: {
    next() {
      this.loading = true;
      setTimeout(() => {
        this.selectedTag = [];
        this.loading = false;
      }, 2000);
    },
    /**
     *
     */
    createPost: function () {
      //バリデーションチェック
      if (!this.$refs.haiku_form.validate()) {
        this.dialog_error = true;
        return;
      }
      var user_scope = firebase.auth().currentUser;
      if (!user_scope) {
        //匿名の場合
        this.createPostNotSignIn();
      } else {
        //ログイン済み
        this.writePostData(false);
        this.dialog = true;
      }
      //タグ追加時はこちらをコメント解除
      // db.ref("tags").push({
      //   text: this.haiku,
      //   createdAt: firebase.database.ServerValue.TIMESTAMP,
      // });
    },

    /*
     *RaltimeDatabaseにデータを書き込む
     */
    writePostData: function (tokumei) {
      var user_scope = firebase.auth().currentUser;
      var imageSet = false;
      if (this.imageCropped != "") {
        imageSet = true;
      }
      var newPostKey = db.ref("haikus").push({
        haiku: this.haiku,
        comment: this.comment,
        user: user_scope.uid,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        publish: tokumei,
        tags: this.selectedTag,
        heartCount: 0,
        image: imageSet,
      }).key;
      //画像のアップロード
      if (this.imageCropped != "") {
        var storageRef = firebase.storage().ref();
        var imagesRef = storageRef.child("images/" + newPostKey);
        imagesRef.putString(this.imageCropped, "data_url").then(function () {
          //画像のURLを取得して
          imagesRef
            .getDownloadURL()
            .then(function (downloadURL) {
              //hakuテーブルにセットしておく
              var updates = {};
              updates["/haikus/" + newPostKey + "/imageURL"] = downloadURL;
              db.ref().update(updates);
            })
            .catch(function (error) {
              self.imageExist = false;
              switch (error.code) {
                case "storage/object-not-found":
                  break;
                case "storage/unauthorized":
                  break;
                case "storage/canceled":
                  break;
                case "storage/unknown":
                  break;
              }
            });
        });
      }
    },

    /*
     * 匿名時
     */
    createPostNotSignIn: function () {
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          this.writePostData(true);
          this.dialogAnnon = true;
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
        });
    },
    cancel: function () {
      this.$router.push("/");
    },
    closeDialg: function () {
      this.dialog = false;
      this.$router.push("/mypage");
    },
    closeDialg_error: function () {
      this.dialog_error = false;
    },
    closeDialgAnnon: function () {
      firebase.auth().signOut();
      this.$router.push("/");
    },
    closeDialogNotSIgninAlert: function () {
      this.dialogNotSIgninAlert = false;
    },
    goToSignInPage: function () {
      this.$router.push("/signin");
    },
    reflectValue(...args) {
      const [cropedimage, originalfile] = args;
      this.imageCropped = cropedimage;
      this.imageFile = originalfile;
    },
  },

  computed: {
    allSelected() {
      return this.selectedTag.length === this.tags.length;
    },
    selected() {
      return this.selectedTag.length > 0;
    },
    categories() {
      return this.tags;
    },
    selections() {
      const selections = [];
      for (const selection of this.selectedTag) {
        selections.push(selection);
      }
      return selections;
    },
  },
};
</script>
<style scoped>
h1,
h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
.new {
  /* margin-top: 20px; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.v-text-field--outlined >>> fieldset {
  border-color: #dcdcdc;
}
</style>

