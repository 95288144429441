<template>
  <div>
    <v-container>
      <banner></banner>
      <v-row dense>
        <v-col cols="12">
          <div v-if="tags">
            <v-sheet elevation="0" color="#f7f9f9">
              <v-chip-group mandatory active-class="primary--text">
                <v-chip class="tagChips" outlined> ホーム </v-chip>
                <v-chip class="tagChips" v-for="tag in tags" :key="tag.index" outlined
                  v-bind:class="{ active: tag.selected }" @click="selectTag(tag.key)">
                  {{ tag.text }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <h3>新着順</h3>
        </v-col>
      </v-row>
      <div v-for="item in sortedItem" v-bind:key="item.haikuid">
        <haikucard :haiku="item"></haikucard>
      </div>
      <button @click="loadMore" class="load-more-button">もっと見る</button>
    </v-container>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import haikucard from "./Haikucard";
import banner from "./Banner";

export default {
  name: "Home",
  components: {
    haikucard,
    banner,
    // bannerOsirase,
    // footerHaikulife,
    // haikucardPicture,
  },
  data() {
    return {
      items: [],
      tags: [],
      lastKey: null, // 最後のキーを保持する変数
    };
  },
  created: function () {
    let self = this;
    this.loadInitialData();
    db.ref("tags/")
      .orderByChild("order")
      .once("value", (snapshot) => {
        self.tags = [];
        snapshot.forEach(function (tag) {
          if (tag.val().publish) {
            if (tag.key === self.$route.params.tagId) {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: true,
              });
              self.selectionTagText = tag.val().text;
            } else {
              self.tags.push({
                text: tag.val().text,
                key: tag.key,
                selected: false,
              });
            }
          }
        });
      });
  },
  computed: {
    sortedItem() {
      return this.items.slice().sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });
    },
  },
  methods: {
    loadInitialData() {
      db.ref("haikus")
        .orderByKey()
        .limitToLast(30)
        .once("value", (snapshot) => {
          let data = [];
          let haikus = snapshot.val();
          if (haikus) {
            Object.keys(haikus).forEach((key) => {
              let haiku = haikus[key];
              db.ref("users/" + haiku.user).once("value", (userSnapshot) => {
                let haigou = "名無し";
                let userid = "";
                if (userSnapshot.val()) {
                  haigou = userSnapshot.val().haigou;
                  userid = userSnapshot.val().userId;
                }
                data.push({
                  haikuid: key,
                  haiku: haiku.haiku,
                  comment: haiku.comment,
                  timestamp: haiku.createdAt,
                  haigou: haigou,
                  userId: userid,
                  tags: haiku.tags,
                  heartCount: haiku.heartCount,
                });
              });
            });
            this.items = data;
            this.lastKey = Object.keys(haikus)[0]; // 最後のキーを保持
          }
        });
    },
    loadMore() {
      db.ref("haikus")
        .orderByKey()
        .endAt(this.lastKey)
        .limitToLast(30) // 11件取得して、最初の1件を除く
        .once("value", (snapshot) => {
          let data = [];
          let haikus = snapshot.val();
          if (haikus) {
            let keys = Object.keys(haikus);
            // 配列の最後の1件を除外
            keys.pop();
            let promises = keys.map((key) => {
              let haiku = haikus[key];
              return db.ref("users/" + haiku.user).once("value").then((userSnapshot) => {
                let haigou = "名無し";
                let userid = "";
                if (userSnapshot.val()) {
                  haigou = userSnapshot.val().haigou;
                  userid = userSnapshot.val().userId;
                }
                data.push({
                  haikuid: key,
                  haiku: haiku.haiku,
                  comment: haiku.comment,
                  timestamp: haiku.createdAt,
                  haigou: haigou,
                  userId: userid,
                  tags: haiku.tags,
                  heartCount: haiku.heartCount,
                });
              });
            });
            Promise.all(promises).then(() => {
              this.items = this.items.concat(data);
              this.lastKey = Object.keys(haikus)[0]; // 最後のキーを更新
            });
          }
        });
    },
    selectTag: function (selectTagIndex) {
      this.$router.push({
        name: "category",
        params: { tagId: selectTagIndex },
      });
    },
  },
};
</script>
<style>
.v-application a {
  color: black !important;
}
.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  /* background-color: gray; */
  color: black;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
</style>
