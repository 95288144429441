<template>
  <div class="haikuimage">
    <h3>画像</h3>
    <div v-if="imgSrc === ''">
      <v-file-input
        v-model="files"
        hide-input
        type="file"
        name="image"
        accept="image/*"
        prepend-icon="mdi-image-plus"
        style="margin:0 0 0 0"
        @change="setImage"
      ></v-file-input>
    </div>
    <v-dialog dark v-model="dialog" overlay-color="white" overlay-opacity="1" max-width=500 >
      <div v-if="imgSrc != ''">
        <vue-cropper
          ref="cropper"
          :guides="true"
          drag-mode="crop"
          :auto-crop-area="1.0"
          :min-container-width="200"
          :min-container-height="200"
          :background="false"
          :rotatable="true"
          :src="imgSrc"
          :aspect-ratio="1 / 1"
          :modal="true"
          style="max-height:500px;"
        >
        </vue-cropper>
      </div>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="clearImage">
          キャンセル
        </v-btn>
        <v-btn color="blue darken-1" text @click="rotate">回転</v-btn>
        <v-btn color="blue darken-1" text @click="cropImage"> 切り出し </v-btn>
      </v-card-actions>
    </v-dialog>
    <v-row justify="center">
      <div v-if="cropImg != ''">
        <img
          :src="cropImg"
          style="width: 200px; height: 200px; border: 0px solid gray"
          alt="Cropped Image"
        />
        <v-spacer></v-spacer>
        <button @click="clearImage">削除</button>
      </div>
    </v-row>
  </div>
</template>
<script>
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";

// const storage = firebase.storage();
// export const storageProfilePhotoRef = storage.ref("profilePhoto");

export default {
  name: "haikuimage",
  components: { VueCropper },
  data() {
    return {
      filename: "",
      imgSrc: "",
      cropImg: "",
      files: [],
      dialog: false,
    };
  },
  methods: {
    setImage() {
      const file = this.files;
      if (file === void 0) {
        this.clearImage();
        return;
      }
      this.filename = file.name;
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          //   this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.dialog = true;
    },
    cropImage() {
      //メモ:backgroundをtrueにすると、上下に数ピクセル余白ができてしまうのでfalseにした
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.dialog = false;
      this.$emit("my-click", this.cropImg, this.files);
    },
    clearImage() {
      this.cropImg = "";
      this.imgSrc = "";
      this.dialog = false;
      this.files = [];
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
  },
};
</script>
<style>
.v-text-field {
  padding: 0 0 0 0;
}
</style>