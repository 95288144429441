<template>
  <div class="Haikucard">
    <v-row>
      <v-col cols="12">
        <v-card outlined elevation="0" class="rounded-card">
          <router-link :to="{ name: 'haiku', params: { id: haiku.haikuid } }">
            <v-card-title class="headline haiku">
              <br />
              {{ haiku.haiku }}
              <br />
            </v-card-title>
          </router-link>

          <div v-if="haiku.userId !== ''">
            <v-card-subtitle>
              <router-link
                :to="{ name: 'user', params: { userId: haiku.userId } }"
                >{{ haiku.haigou }}
              </router-link>
            </v-card-subtitle>
          </div>
          <div v-else>
            <v-card-subtitle>
              {{ haiku.haigou }}
            </v-card-subtitle>
          </div>
          <v-container>
            <v-row align="center" justify="end" no-gutters>
              <tags :tags="haiku.tags"></tags>
              <v-spacer></v-spacer>
              <heart
                :haikuid="haiku.haikuid"
                :heartCount="haiku.heartCount"
              ></heart>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import heart from "./Heart.vue";
import tags from "./Tags.vue";

export default {
  name: "haiku",
  components: {
    heart,
    tags,
  },
  props: ["haiku"],
};
</script>
<style>
.haiku {
  border-radius: 50px;
  word-break: break-all;
  white-space: normal;
  font-weight: bold !important;
  color: black;
}
.rounded-card {
  border-radius: 10px !important;
}
</style>
