<template>
  <div class="passwrodReset">
    <v-container class="text-center">
      <v-row>
        <v-col class="subtitle" style="margin-right: 30px; margin-left: 30px">
          <h2>パスワード再設定</h2>
          <v-form>
            <v-text-field
              label="メールアドレス"
              placeholder=""
              v-model="email"
            ></v-text-field>
            <v-btn
              class="ma-3"
              depressed
              outlined
              color=""
              large
              @click="resetPassword"
              >再設定メールを送信</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link to="/signin">戻る</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  name: "Signin",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    resetPassword: function () {
      var auth = firebase.auth();
      auth
        .sendPasswordResetEmail(this.email)
        .then(function () {
          alert("メールを送信しました。メールをご確認ください。");
        })
        .catch(function (error) {
          if (error.code === "auth/user-not-found") {
            alert("存在しないメールアドレスです");
          } else if (error.code === "auth/invalid-email") {
            alert("メールアドレスの形式が正しくありません");
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>
<style scoped>
.signin {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
input {
  margin: 10px 0;
  padding: 10px;
}
</style>

