<template>
  <div class="tags">
      <v-chip class="tagChips" v-for="tag in tags" v-bind:key="tag.id">
        {{ tag.text }}
      </v-chip>
  </div>
</template>
<script>
export default {
  name: "tags",
  props: ["tags"],
  data() {
    return {
      tagsData: this.tags,
    };
  },
};
</script>
<style>
.tagChips {
  margin-left: 5px !important;
  margin-bottom: 5px !important;
}
</style>


