<template>
  <v-footer padless class="footer">
    <v-row justify="center" no-gutters class="lighten-3">
      <v-col>
        <v-btn text rounded>
          <router-link :to="{ name: 'privacy' }">プライバシー </router-link>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn text rounded>
          <router-link :to="{ name: 'terms' }">利用規約 </router-link>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn text rounded>
          <router-link :to="{ name: 'about' }">俳句ノート </router-link>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {};
</script>