<template>
  <div class="explanation">
    <v-container class="text-center">
      <div class="py-2"></div>
      <v-row
        justify="center"
        align-content="center"
        style="max-width: 800px; margin: 0 auto"
      >
        <v-col cols="11" class="title">俳句ノートとは</v-col>
        <v-col cols="11" class="body-1">
          詠んだ俳句を公開することで、このサイトを訪れた方に見てもらうことができる、俳句投稿サイトです。
          また、公開せずにコメントと一緒に保存して、あなただけの俳句集を作ることもできます。無料でつかえます。
        </v-col>
      </v-row>
      <div class="py-7"></div>
      <v-row
        justify="center"
        align-content="center"
        style="max-width: 800px; margin: 0 auto"
      >
        <v-col cols="11" class="title">俳句って？</v-col>
        <v-col cols="11" class="body-1">
          俳句は「季語」と「五・七・五」を基本とした定型詩です。また、季語がない「無季俳句」や、五・七・五の形にとらわれない「自由律俳句」もあります。
          俳句ノートでは、できればそういう定型にはとらわれないで、気軽にたくさんの人に、俳句を投稿してもらいたいと考えています。
        </v-col>
      </v-row>
      <div class="py-7"></div>
      <v-row justify="center" align-content="center">
        <v-col cols="11" class="body-1">
          俳句ノートで俳句を詠んでみませんか？
        </v-col>
      </v-row>
      <div class="py-7"></div>
      <v-btn depressed outlined color="#FAFAFA">
        <v-img
          :src="require('@/assets/Twitter_Social_Icon_Circle_Color.svg')"
          height="25"
          max-width="25"
          style="margin-right:5px;"
        />
        <a
          href="http://twitter.com/share?url=https://haikunote.jp/&text=俳句ノート&hashtags=俳句"
          target="_blank"
          >ツイートする</a
        >
      </v-btn>

      <div class="py-7"></div>
      <v-btn class="ma-3" depressed  outlined color="" large @click="signup"
        >閉じる</v-btn
      >
      <div class="py-2"></div>
      <v-row justify="center" align-content="center">
        <v-col cols="8" class="caption">
          ぜひご意見をお寄せ下さい。
          <br />
          y.okamoto@haikunote.jp
        </v-col>
      </v-row>
    </v-container>
    <div class="py-12"></div>
    <footerHaikulife></footerHaikulife>
  </div>
</template>
<script>
import footerHaikulife from "./Footer";

export default {
  components: {
    footerHaikulife,
  },
  methods: {
    signup: function () {
      this.$router.push("/signup");
    },
  },
};
</script>
<style scoped>
</style>
