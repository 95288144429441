<template>
  <div class="HaikucardMypage">
    <v-row>
      <v-col cols="12">
        <v-card
          outlined
          elevation="0"
          style="max-width: 1000px; margin: 0 auto"
        >
          <router-link
            :to="{ name: 'haiku', params: { id: haiku_card.haikuid } }"
          >
            <v-card-title class="headline haiku">
              <br />
              {{ haiku.haiku }}
            </v-card-title>
          </router-link>
          <v-card-actions>
            <v-list-item>
              <v-container>
                <v-container>
                  <v-row align="center" justify="end">
                    <div v-if="haiku_card.publish">公開中</div>
                    <div v-if="!haiku_card.publish">非公開</div>
                    <v-spacer></v-spacer>
                    <heart
                      :haikuid="haiku_card.haikuid"
                      :heartCount="haiku_card.heartCount"
                    ></heart>
                  </v-row>
                  <v-row align="center" justify="start">
                    {{ haiku_timestamp }}
                  </v-row>
                </v-container>
                <v-row align="center">
                  <v-btn
                    class="ma-1 unpublishbtn"
                    v-bind:disabled="!haiku_card.publish"
                    depressed
                    outlined
                    color=""
                    @click="publishHaiku(haiku_card.haikuid, false)"
                  >
                    非公開にする
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    v-bind:disabled="haiku_card.publish"
                    depressed
                    color="primary"
                    @click="publishHaiku(haiku_card.haikuid, true)"
                  >
                    公開する
                  </v-btn>
                  <v-spacer></v-spacer>
                  <router-link
                    :to="{ name: 'edit', params: { haikuId: haiku.haikuid } }"
                  >
                    編集
                  </router-link>
                </v-row>
              </v-container>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog_publish" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"> 公開しました。 </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_unpublish" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"> 非公開にしました。 </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeDialg">
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import heart from "./Heart.vue";
import { db } from "../plugins/firebase";

export default {
  name: "HaikucardMypage",
  components: {
    heart,
  },
  props: ["haiku"],
  data() {
    return {
      dialog_publish: false,
      dialog_unpublish: false,
      //プロパティの値はそのまま使わない
      haiku_card: this.haiku,
      haiku_timestamp: "",
    };
  },
  created: function () {
    let dateTime = new Date(this.haiku.timestamp);
    this.haiku_timestamp =
      dateTime.getFullYear() +
      "年" +
      (dateTime.getMonth() + 1) +
      "月" +
      dateTime.getDate() +
      "日 " +
      dateTime.getHours() +
      "時";
  },
  methods: {
    publishHaiku: function (index, status) {
      var updates = {};
      updates["/haikus/" + index + "/publish"] = status;
      db.ref().update(updates);
      if (status) {
        this.dialog_publish = true;
        this.haiku_card.publish = true;
      } else {
        this.dialog_unpublish = true;
        this.haiku_card.publish = false;
      }
    },
    closeDialg: function () {
      this.dialog_publish = false;
      this.dialog_unpublish = false;
      // this.reload();
    },
    // reload() {
    //   this.$router.go({ path: this.$router.currentRoute.path, force: true });
    // },
  },
};
</script>
<style>
.haiku {
  word-break: break-all;
  white-space: normal;
  font-weight: bold !important;
  color: black;
}
.unpublishbtn {
  border-color: #dcdcdc;
}
</style>
