import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './plugins/firebase'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';




Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  VueCropper,
  render: h => h(App)
}).$mount('#app')



