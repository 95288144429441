<template>
  <div class="user">
    <v-container>
      
      <div>俳号：{{ pageowner.haigou }}</div>
      <v-row v-if="sortedItem.length===0">
        <v-col >
        <div  class="grey--text">
          公開されている俳句はありません。<br>
        </div>
        </v-col>
      </v-row>
      <div v-for="haiku in sortedItem" :key="haiku.haikuId">
        <haikucard :haiku="haiku"></haikucard>
      </div>
    </v-container>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import haikucard from "./Haikucard";

export default {
  name: "user",
  components: {
    haikucard,
  },
  data() {
    return {
      comment: "",
      haikus: [],
      pageowner_uid: "",
      pageowner: "",
    };
  },
  created: function () {
    db.ref("users")
      .orderByChild("userId")
      .equalTo(this.$route.params.userId)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          this.pageowner = element.val();
          //検索用にkey値を保存
          this.pageowner_uid = element.key;
          this.getHaikusu(element.key);
        });
      });
  },
  methods: {
    getHaikusu: function (owner_uid) {
      let self = this;
      db.ref("haikus/")
        .orderByChild("user")
        .equalTo(owner_uid)
        .once("value", (snapshot) => {
          let data = [];
          snapshot.forEach(function (item) {
            if (item.val().publish) {
              data.push({
                haikuid: item.key,
                haiku: item.val().haiku,
                comment: item.val().comment,
                timestamp: item.val().createdAt,
                haigou: "",
                userId: owner_uid,
                heart: false,
                heartCount: item.val().heartCount,
              });
            }
          });
          self.haikus = data;
        });
    },
  },
  computed: {
    sortedItem() {
      return this.haikus.slice().sort((a, b) => {
        if (a.timestamp < b.timestamp) return 1;
        if (a.timestamp > b.timestamp) return -1;
        return 0;
      });
    },
  },
};
</script>