<template>
  <div class="signup">
    <v-container class="text-center">
      <v-row>
        <v-col class="subtitle" style="margin-right: 30px; margin-left: 30px">
          <h2>俳句ノートに登録</h2>
          <v-form>
            <v-text-field
              label="俳号（あとで変更できます）"
              placeholder=""
              v-model="haigou"
            />
          </v-form>
          <v-form>
            <v-text-field
              label="ID（3文字以上の半角英数字）"
              placeholder=""
              v-model="userId"
            />
          </v-form>
          <v-form>
            <v-text-field label="メールアドレス" placeholder="" v-model="email"
          /></v-form>
          <v-form>
            <v-text-field
              label="パスワード（6文字以上）"
              placeholder=""
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            />
          </v-form>
          <div class="my-1">
            <router-link class="termslink" to="/terms">利用規約</router-link>・
            <router-link class="termslink" to="/privacy"
              >プライバシーポリシー</router-link
            > に同意して
          </div>

          <v-btn class="ma-3" depressed outlined color="" large @click="signUp"
            >登録する</v-btn
          >
          <router-link :to="{ name: 'about' }">
            <div class="my-5 subtitle-1">俳句ノートとは？</div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-6"></div>
    <footerHaikulife></footerHaikulife>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { db } from "../plugins/firebase";
import footerHaikulife from "./Footer";

export default {
  name: "Signup",
  components: {
    footerHaikulife,
  },
  data() {
    return {
      email: "",
      password: "",
      haigou: "",
      userId: "",
      show1: false,
    };
  },
  methods: {
    isIDOK: function (haigou, userId) {
      return new Promise(function (resolve, reject) {
        db.ref("users").once("value", (users) => {
          users.forEach((element) => {
            if (element.val().userId === userId) {
              return reject(
                "すでに利用されているIDです。他のIDを指定して下さい"
              );
            }
            if (element.val().haigou === haigou) {
              return reject("すでに利用されている俳号です。");
            }
          });
          return resolve();
        });
      });
    },
    signUp: function () {
      let self = this;
      if (!this.isHaigouOK(this.haigou)) {
        alert("俳号は必須入力です");
        return;
      }
      if (!this.isUserIDlengthOK(this.userId)) {
        alert("IDは3文字以上で指定して下さい");
        return;
      }
      if (!this.isHanEisu(this.userId)) {
        alert("IDは半角英数で入力してください");
        return;
      }

      //IDチェックがOKだったときの処理
      function onIDOK() {
        firebase
          .auth()
          .createUserWithEmailAndPassword(self.email, self.password)
          .then(() => {
            let currentUser = firebase.auth().currentUser;
            db.ref("users/" + currentUser.uid).set({
              photoUrl: currentUser.photoURL,
              haigou: self.haigou,
              userId: self.userId,
              email: self.email,
            });
            alert("会員登録を完了しました。");
            self.$router.push("/");
            currentUser
              .sendEmailVerification()
              .then(function () {
                console.log("send email");
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch((error) => {
            if (error.code == "auth/invalid-email") {
              alert("メールの形式が正しくありません。");
            } else if (error.code == "auth/email-already-in-use") {
              alert("すでに登録されていているメールアドレスです");
            } else if (error.code == "auth/weak-password") {
              alert("パスワードは6文字以上設定して下さい");
            } else {
              alert(error.message);
            }
          });
      }
      //IDチェックがNGだったときの処理
      function onRejectedIDOK(error) {
        alert(error);
      }
      this.isIDOK(this.haigou, this.userId).then(onIDOK, onRejectedIDOK);
    },

    isUserIDlengthOK: function (str) {
      str = str == null ? "" : str;
      if (str.length >= 3) {
        return true;
      } else {
        return false;
      }
    },
    isHanEisu: function (str) {
      str = str == null ? "" : str;
      if (str.match(/^[A-Za-z0-9]+$/)) {
        return true;
      } else {
        return false;
      }
    },
    isHaigouOK: function (str) {
      if (str !== "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.signup {
  margin-top: 20px;
  /* display: flex; */
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
input {
  margin: 10px 0;
  padding: 10px;
}
.v-text-field {
  max-width: 500px;
  margin: 0 auto;
}
.termslink {
  text-decoration: underline;
}
.my-1 {
  font-size: 10px;
}
</style>

