<template>
  <div class="heart">
    <v-btn v-if="!displayType" icon @click="hearttoggle(haikuid, true)">
      <v-icon>mdi-heart-outline</v-icon>{{ heartCountUser }}
    </v-btn>
    <v-btn
      v-if="displayType"
      icon
      color="pink"
      @click="hearttoggle(haikuid, false)"
    >
      <v-icon>mdi-heart</v-icon>{{ heartCountUser }}
    </v-btn>
  </div>
</template>
<script>
import { db } from "../plugins/firebase";
import mixin from "../plugins/mixin.js";
// import firebase from "firebase";

export default {
  mixins: [mixin],

  props: {
    haikuid: { type: String, default: "" },
    heartCount: { type: Number, default: 0 },
  },
  data() {
    return {
      //プロパティの値はそのまま使わない
      heartCountUser: this.heartCount,
      displayType: false,
    };
  },
  created: function () {
    db.ref("users/" + this.user.uid + "/heartHaikus").once(
      "value",
      (snapshot) => {
        snapshot.forEach((element) => {
          if (element.val()) {
            if (element.key == this.haikuid) {
              this.displayType = true;
            }
          }
        });
      }
    );
  },
  methods: {
    hearttoggle: function (haikuid, heart) {
      var newHeartCount = this.heartCountUser;
      if (heart) {
        newHeartCount++;
      } else {
        newHeartCount--;
      }
      var updates = {};
      var user_uid;
      const date1 = new Date();
      if(this.user.uid=== void 0){
        //匿名の場合
        user_uid = "-anonymous/" + date1.getTime();
      }else{
        user_uid = this.user.uid;
      }
      updates["/users/" + user_uid + "/heartHaikus/" + haikuid] = heart;
      updates["/haikus/" + haikuid + "/heartCount/"] = newHeartCount;
      db.ref().update(updates);
      this.heartCountUser = newHeartCount;
      this.displayType = !this.displayType;
    },
  },
};
</script>